<template>
  <div>
  </div>
</template>
<script>
  export default {
    components: {
    },
    props: {
      config: {
        default() {
          return {}
        }
      },
    },
    data() {
      return {
      }
    },
    inject: {
      publicData: {
        default: {}
      },
      getFunc: {
      }
    },
    computed: {
      audio() {
        return this.config.audio || {}
      },
      tour() {
        return this.publicData.tour || {}
      },
      senceGuid() {
        return this.publicData.senceGuid
      },
      groupGuid() {
        return this.publicData.groupGuid
      },
    },
    watch: {
      senceGuid() {
        setTimeout(this.makeAudios, 1)
      },
      audio() {
        setTimeout(this.makeAudios, 1)
      }
    },
    created() {
    },
    mounted() {
      this.$emit('ready', {
        vm: this,
        funcs: {
          changeBackAudio: this.changeBackAudio
        }
      })
      this.makeAudios()
    },
    destroyed() {
    },
    methods: {
      changeBackAudio() {
        var audiosConfig = this.audiosConfig
        if (!audiosConfig.backAudio) {
          audiosConfig.backAudio = {}
        }
        if (audiosConfig.backAudio.play) {
          audiosConfig.backAudio.play = false
        } else {
          audiosConfig.backAudio.play = true
        }
      },
      makeAudios() {
        var audios = {}, audiosConfig = this.publicData.audiosConfig || {}
        if (this.audio == 'tour') {
          audios.backAudio = this.tour.audio
          audiosConfig.backAudio = { ...audiosConfig.backAudio, autoPlay: this.tour.audioAutoPlay, loop: this.tour.audioLoop, priority: 15 }
        } else if (this.audio == 'group') {
          audios.backAudio = this.tour.audio
          audiosConfig.backAudio = { ...audiosConfig.backAudio, autoPlay: this.tour.audioAutoPlay, loop: this.tour.audioLoop, priority: 15 }
          var g = this.getFunc({ target: 'groups', name: 'getGroup' })(this.groupGuid) || {}
          console.log('makeAudios group', g, this.groupGuid)
          audios.defaultAudio = g.audio
          audiosConfig.defaultAudio = { ...audiosConfig.defaultAudio, autoPlay: g.audioAutoPlay, loop: g.audioLoop, priority: 100 }
        } else if (this.audio == 'pano') {
          var g = this.getFunc({ target: 'groups', name: 'getGroup' })(this.groupGuid) || {}
          var p = this.getFunc({ target: 'Data', name: 'getPano' })(this.senceGuid) || {}
          if (p.audio) {
            audios.defaultAudio = p.audio
            audiosConfig.defaultAudio = { ...audiosConfig.defaultAudio, autoPlay: p.audioAutoPlay, loop: p.audioLoop, priority: 100 }
            if (g.audio) {
              audios.backAudio = g.audio
              audiosConfig.backAudio = { ...audiosConfig.backAudio, autoPlay: g.audioAutoPlay, loop: g.audioLoop, priority: 15 }
            } else {
              audios.backAudio = this.tour.audio
              audiosConfig.backAudio = { ...audiosConfig.backAudio, autoPlay: this.tour.audioAutoPlay, loop: this.tour.audioLoop, priority: 15 }
            }
          } else {
            audios.defaultAudio = g.audio
            audiosConfig.defaultAudio = { ...audiosConfig.defaultAudio, autoPlay: g.audioAutoPlay, loop: g.audioLoop, priority: 100 }
            audios.backAudio = this.tour.audio
            audiosConfig.backAudio = { ...audiosConfig.backAudio, autoPlay: this.tour.audioAutoPlay, loop: this.tour.audioLoop, priority: 15 }
          }
        }
        this.getFunc('changePublicData')({ audiosConfig, audios, })
      }
    },
  }
</script>
<style scoped>
  .fine {
    position: absolute;
    top: 1.4rem;
    right: 1.4rem;
    font-size: 1.5rem;
    text-shadow: 0 0 2px red;
    color: orange;
    font-weight: bolder;
  }
</style>
